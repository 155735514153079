import Icon from '@mdi/react';
import { Toggletip, ToggletipArrow, ToggletipContent, ToggletipTrigger } from '@purinanbm/pds-ui';
import React from 'react';
import { mdiInformation } from 'src/assets/icons/mdiIcons';

const PurinaToggletip: React.FC<React.ComponentProps<typeof Toggletip>> = ({
  children,
  ...props
}) => {
  return (
    <Toggletip {...props}>
      <ToggletipTrigger>
        <Icon size={1} path={mdiInformation} color="black" />
      </ToggletipTrigger>
      <ToggletipContent
        className="pds-z-10 pds-max-w-[300px] pds-shadow-strong md:pds-max-w-[400px]"
        side="bottom"
      >
        {children}
        <ToggletipArrow className="pds-shadow-strong" />
      </ToggletipContent>
    </Toggletip>
  );
};

export default PurinaToggletip;
