import { PluginOptions } from 'gatsby';
import { checkOneTrustMount } from 'gatsby-plugin-purina-analytics/wrapPageElement';
import React from 'react';
import ScrollTracker from './src/ScrollTracker';
import { searchExceptions } from './src/common/constants';
import {
  generateGaEvent,
  replaceRouteExceptions,
  sendLoginOnIntialSignIn,
} from './src/common/functions';
import { Optimizely } from './src/optimizely';
import { IPluginOptions, IStopPoint } from './src/types';

// Leverage route update function to push the page view event.
export const onRouteUpdate = (
  {
    location,
    prevLocation,
  }: {
    location: { pathname: string; search: string; href: string };
    prevLocation: { pathname: string };
  },
  pluginOptions: IPluginOptions,
) => {
  /**
   * Placeholder Event for login until PUP turns off autoLogin
   *
   * TODO: Deprecate once and if AutoLogin is turned off in gatsby browser
   */
  if (process.env.GATSBY_AUTOLOGIN_ANALYTICS === 'true') {
    sendLoginOnIntialSignIn(location);
  }

  checkOneTrustMount(
    0,
    () => {
      setTimeout(function () {
        const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');
        for (let i = 0; i < toggleDisplay.length; i++) {
          toggleDisplay[i].addEventListener('click', function (e) {
            e.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          });
        }
      }, 1000);
    },
    pluginOptions.gtmID,
  );

  // Reset scroll tracker state.
  if (prevLocation && window.resetScrollDepth) {
    window.resetScrollDepth();
  }
};

window.addEventListener(
  'click',
  function (event) {
    const target = event.target as HTMLElement;
    if (target?.classList?.contains('js-track') || target?.closest('.js-track')) {
      const eventTarget = event.target as HTMLAnchorElement;
      const stopPoints: IStopPoint[] = [];
      const keyTarget = eventTarget?.classList?.contains('js-track')
        ? eventTarget
        : eventTarget.closest('.js-track');
      let el: any = eventTarget.closest('.js-track-stop-point');

      while (el) {
        if (el?.classList?.contains('js-track-stop-point')) {
          const type = el.getAttribute('data-type') || '[not set]'; // page, module, component
          const pageType = el.getAttribute('data-page-type') || '[not set]';
          const typeLabel = el.getAttribute('data-type-label') || '[not set]';
          const label = el.getAttribute('data-label') || '[not set]'; // Component Testing for Analytics, 'Page title component'
          const title = el.getAttribute('data-title') || '[not set]';
          const action = el.getAttribute('data-action') || undefined;
          const eventLabel = el.getAttribute('data-event-label') || undefined;
          const category = el.getAttribute('data-category') || undefined;
          const additionalParams = el.getAttribute('data-additional-params') || undefined;
          const eventOverride = el.getAttribute('data-event-override') || undefined;
          let addParentLevel = Number(el.getAttribute('data-parent-level' || 0));
          let trueParent = el.parentNode;
          let siblingElement = el;

          if (addParentLevel > 0) {
            // eslint-disable-next-line
            while (addParentLevel > 0) {
              siblingElement = trueParent;
              trueParent = trueParent.parentNode;
              addParentLevel--;
            }
          }

          const siblingArray = Array.from(trueParent?.children || []);
          const index = siblingArray.indexOf(siblingElement);
          const totalSiblings = siblingArray.length;

          stopPoints.push({
            type: type as 'page' | 'module' | 'component',
            label,
            typeLabel,
            title,
            action,
            category,
            pageType,
            index,
            eventLabel,
            eventOverride,
            additionalParams,
            totalSiblings,
          });
        }
        el = el.parentNode;
      }

      // console.group('GA event test logging');
      // console.log({ event });
      // console.log({ eventTarget });
      // console.log({ stopPoints });
      // console.log({ keyTarget });
      // console.log({ el });
      // console.groupEnd();
      generateGaEvent(stopPoints, keyTarget);
    }
  },

  true,
);

export const wrapPageElement = ({ element, props }: any, pluginOptions: PluginOptions) => {
  const { gtmID, dataLayerName, routeChangeEventName: eventName } = pluginOptions;
  const data = dataLayerName || 'dataLayer';
  checkOneTrustMount(
    0,
    () => {
      window[data as string].push(
        {
          event: eventName || 'page_view',
          event_params: {
            page_path: replaceRouteExceptions(
              searchExceptions,
              window.location.pathname,
              'replacedPII',
            ),
            page_type:
              (props.pageContext?.pageType as string) || // For handling custom pages.
              (props.data?.node?.internal?.type as string)?.replace('node__', '') ||
              'page',
          },
        },
        { event_params: undefined },
      );
    },
    gtmID as string,
  );
  return (
    <Optimizely>
      <ScrollTracker>{element}</ScrollTracker>
    </Optimizely>
  );
};
