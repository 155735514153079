import React, { ReactNode, useCallback } from 'react';
import useScrollTracker from './useScrollTracker';

const SCROLL_DEPTHS = [25, 50, 75, 100];
const MIN_SCROLL = 200;

type CallbackParams = {
  scrollY: number;
  scrollPercent: number;
  remainingDepths: Array<number>;
};

const gaCallback = ({ scrollY }: CallbackParams) => {
  const dataLayerName = process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer';
  // sendIt function needs additional params, while this event only needs the following properties:
  window[dataLayerName].push({
    event: 'scroll_depth',
    scroll_direction: 'vertical',
    scroll_threshold: scrollY,
    scroll_units: 'percent',
  });
};

const ScrollTracker: React.FC<{ children: ReactNode }> = ({ children }: any) => {
  const callback = useCallback(gaCallback, []);
  useScrollTracker(SCROLL_DEPTHS, MIN_SCROLL, callback);

  return children;
};

export default ScrollTracker;
