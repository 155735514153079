import {
  IPurinaProfileConfig,
  ISSOUtilityModalConfig,
} from '@purinanbm/pup-script/dist/common/types';

export const SVG_PATHS = {
  mdiCampaign:
    'M16.5 10.083C16.5 10.6972 16.5 11.3022 16.5 11.9163C17.6 11.9163 19.03 11.9163 20.1667 11.9163C20.1667 11.3022 20.1667 10.6972 20.1667 10.083C19.03 10.083 17.6 10.083 16.5 10.083Z M14.6667 16.1428C15.5467 16.7937 16.6926 17.6553 17.6001 18.3337C17.9667 17.8478 18.3334 17.3528 18.7001 16.867C17.7926 16.1887 16.6467 15.327 15.7667 14.667C15.4001 15.162 15.0334 15.657 14.6667 16.1428Z M18.7001 5.13366C18.3334 4.64783 17.9667 4.15283 17.6001 3.66699C16.6926 4.34533 15.5467 5.20699 14.6667 5.86699C15.0334 6.35283 15.4001 6.84783 15.7667 7.33366C16.6467 6.67366 17.7926 5.82116 18.7001 5.13366Z M3.66659 8.25C2.65825 8.25 1.83325 9.075 1.83325 10.0833V11.9167C1.83325 12.925 2.65825 13.75 3.66659 13.75H4.58325V17.4167H6.41659V13.75H7.33325L11.9166 16.5V5.5L7.33325 8.25H3.66659ZM8.27742 9.8175L10.0833 8.73583V13.2642L8.27742 12.1825L7.83742 11.9167H7.33325H3.66659V10.0833H7.33325H7.83742L8.27742 9.8175Z M14.2083 10.9995C14.2083 9.78038 13.6766 8.68038 12.8333 7.92871V14.0612C13.6766 13.3187 14.2083 12.2187 14.2083 10.9995Z',
};

type PupEnv = {
  [key: string]: IPurinaProfileConfig;
};

const pupAnalytics = {
  formBrand: 'purina',
  formCampaignId: 'purina campaign id',
  formId: 'purina form id',
  formName: 'purina',
  formParent: 'purina',
  formType: 'purina form type',
  loginSourceCode: 'PUPurinaUniversalLogin',
  signUpSourceCode: 'PUPurinaUniversalRegistration',
};

const PUP_ENV: PupEnv = {
  PROD: {
    cognito: {
      userPoolWebClientId: '1jis98pkt1qmf4eusvq3ak4p9b',
      region: 'us-east-1',
      userPoolId: 'us-east-1_tGBOrXu9i',
      cookieStorage: {
        domain: 'purina.com',
      },
      oauth: {
        domain: 'login.purina.com',
        redirectSignOut: `${process.env.GATSBY_SITE_URL}` || 'http://localhost:8000/',
        redirectSignIn: `${process.env.GATSBY_SITE_URL}` || 'http://localhost:8000/',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      },
    },
    pup: {
      logging: true,
      dataLayerName: process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer',
      property: 'purina',
      url: 'https://myprofile.purina.com',
    },
    analytics: pupAnalytics,
  },
  STAGE: {
    cognito: {
      userPoolWebClientId: '19e9nfsrd621m0btgfv9cf68rt',
      userPoolId: 'us-east-1_qRr0H6HX2',
      region: 'us-east-1',
      cookieStorage: {
        domain: 'purina.com',
      },
      oauth: {
        domain: 'login-ts.purina.com',
        redirectSignOut: `${process.env.GATSBY_SITE_URL}`,
        redirectSignIn: `${process.env.GATSBY_SITE_URL}`,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      },
    },
    pup: {
      logging: true,
      dataLayerName: process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer',
      property: 'purina',
      url: 'https://stage-myprofile.purina.com',
    },
    analytics: pupAnalytics,
  },
  LOCAL: {
    cognito: {
      userPoolWebClientId: '19e9nfsrd621m0btgfv9cf68rt',
      userPoolId: 'us-east-1_qRr0H6HX2',
      region: 'us-east-1',
      oauth: {
        domain: 'login-ts.purina.com',
        redirectSignOut: 'http://localhost:3000/',
        redirectSignIn: 'http://localhost:3000/',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      },
    },
    pup: {
      logging: true,
      dataLayerName: process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer',
      property: 'purina',
      url: 'https://stage-myprofile.purina.com',
    },
    analytics: pupAnalytics,
  },
};

export const PROFILE_CONFIG: IPurinaProfileConfig = {
  ...PUP_ENV[process.env.GATSBY_PUP_ENV || 'STAGE'],
};

export const SSO_UTILITY_MODAL_CONFIG: ISSOUtilityModalConfig = {
  cognito: PUP_ENV[process.env.GATSBY_PUP_ENV || 'STAGE'].cognito,
  pup: PUP_ENV[process.env.GATSBY_PUP_ENV || 'STAGE'].pup,
  utilityModal: {
    heading: 'Sign in to your Purina account',
    subheading: 'Sign in to access your Purina account',
    createAccountButtonText: 'Create an account',
    loginButtonText: 'Sign in',
    // image: 'https://via.placeholder.com/150',
    // logo: 'https://via.placeholder.com/150',
    // // theme: {
    // //   primary: '#FFD700',
    // //   primaryAction: '#000000',
    // //   primaryActive: '#000000',
    // //   text: '#000000',
    // // },
  },
};
