import { GatsbyBrowser } from 'gatsby';

function filterObjectByKeys(obj: Object, allowedKeys: Array<string>) {
  return Object.fromEntries(Object.entries(obj).filter(([key]) => allowedKeys.includes(key)));
}

const PET_FOOD_FINDER_KEYS = [
  'baseUrl',
  'isProduction',
  'pricespider_widget_id',
  'consumer_key',
  'client_id',
  'theme_key',
  'env',
];
const FEEDING_GUIDE_KEYS = ['baseUrl', 'isProduction', 'env'];

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  if (props.pageContext.pageType === 'feeding_guide') {
    window.drupalSettings = {};
    window.drupalSettings.tesla = filterObjectByKeys(props.pageContext, FEEDING_GUIDE_KEYS);
  }

  if (props.pageContext.pageType === 'pet_food_finder') {
    window.drupalSettings = {};
    window.drupalSettings.tesla = filterObjectByKeys(props.pageContext, PET_FOOD_FINDER_KEYS);
  }

  return element;
};

export const registerServiceWorker = () => true;
