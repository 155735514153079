import { WrapPageElementBrowserArgs } from 'gatsby';
import * as React from 'react';
import ReactQueryWrapper from 'src/components/ReactQueryWrapper';
import LaunchDarklyFlags from './components/launch_darkly/LaunchDarklyFlags';

// eslint-disable-next-line react/function-component-definition
export const WrapRootWithProvider = ({ element }: { element: React.ReactNode }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <ReactQueryWrapper>{element}</ReactQueryWrapper>;
};

export const WrapPageWithProvider: (args: WrapPageElementBrowserArgs) => React.ReactNode = ({
  element,
}) => {
  return (
    <>
      <LaunchDarklyFlags />
      {element}
    </>
  );
};
