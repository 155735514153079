// eslint-disable-next-line
export const checkOneTrustMount = (count: number, callback: any, gtmID: string) => {
  if (window?.google_tag_manager?.[gtmID]?.dataLayer.get('OnetrustActiveGroups')) {
    return callback();
  }
  if (count === 100) {
    return false;
  }

  setTimeout(() => {
    checkOneTrustMount(count + 1, callback, gtmID);
  }, 50);
};
