/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import { WrapPageWithProvider, WrapRootWithProvider } from './src/wrap-with-provider';

require('./src/styles/global.css');
require('bootstrap/dist/css/bootstrap.min.css');

require('@purinanbm/pup-script/dist/react/styles/index.css');

export const wrapRootElement = WrapRootWithProvider;
export const wrapPageElement = WrapPageWithProvider;

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const prevLocation = prevRouterProps?.location;

  if (
    prevLocation &&
    prevLocation.pathname === location.pathname &&
    (prevLocation.search !== location.search || prevLocation.hash !== location.hash)
  ) {
    return false;
  }

  return window.scrollTo(0, 0);
};

// export const onRouteUpdateDelayed = () => {
//   window.___emitter.emit('routeDelayed');
// };
