// eslint-disable-next-line import/no-extraneous-dependencies
import isObject from 'lodash/isObject';
import { useEffect } from 'react';

const optimizelyPages = window?.optimizely?.get('data').pages;

function activateOptimizelyPage(optlyPage: { apiName: string }) {
  const { apiName } = optlyPage;

  window?.optimizely?.push({
    type: 'page',
    pageName: apiName,
    isActive: false,
  });

  window?.optimizely?.push({
    type: 'page',
    pageName: apiName,
  });
}

export const useGetOptimizelyReactivate = () => {
  useEffect(() => {
    if (!optimizelyPages || !isObject(optimizelyPages)) return;
    Object.values(optimizelyPages).forEach((value: unknown) => {
      const page = value as { apiName?: string };
      if (page && page?.apiName) {
        activateOptimizelyPage({ apiName: page.apiName });
      }
    });
  }, []);
};
