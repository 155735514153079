/* eslint-disable no-useless-escape */
import { debounce } from 'lodash';

export const onRouteUpdate = ({ location }) => {
  // Check if the script has loaded
  const pricespiderLoaded =
    typeof window !== 'undefined' &&
    window.PriceSpider !== undefined &&
    typeof window.PriceSpider.rebind === 'function';
  const currentPathLang = location.pathname.split('/')[1];

  const langs = ['es'];
  let lang = 'en';
  langs.forEach(item => {
    if (currentPathLang === item) {
      lang = item;
    }
  });

  const metaPsLang = document.querySelector('meta[name="ps-language"]');
  if (metaPsLang) {
    metaPsLang.setAttribute('content', lang);
  }

  // If the script has loaded, run your code
  if (pricespiderLoaded) {
    // Assign the language
    window.PriceSpider.language = lang;
    // Assigning to new function on PriceSpider object for easier testing.
    window.PriceSpider.debouncedRebind = debounce(window.PriceSpider.rebind, 500);
  } else {
    // If the script hasn't loaded, wait and try again
    setTimeout(onRouteUpdate, 50);
  }

  // If the body and html tags have overflow-y inline styles, remove them.
  // As a third-party mutation to DOM, we must remove them to prevent their persistence.
  const body = document.querySelector('body');
  const html = document.querySelector('html');
  if (body && html) {
    if (body.style.overflowY === 'hidden') {
      body.style.overflowY = '';
    }
    if (html.style.overflowY === 'hidden') {
      html.style.overflowY = '';
    }
    if (html.classList.contains('ps-open')) {
      html.classList.remove('ps-open');
    }
  }
};
