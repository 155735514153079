/* eslint-disable */
/**
 * Global constants that are used in both /src and /plugins
 **/
export const searchExceptions = [
  // Simple email not needed for now
  // '^([a-zA-Z0-9]{0,}@[a-zA-Z0-9]{0,}\\.[a-zA-Z0-9]{0,})$',
  // Phone
  '((\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4})',
  // Any valid email email
  '((([^<>()[\\]\\.,;:\\s@"]+(\.[^<>()[\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))',
  // Social security
  '([0-9]{3}-[0-9]{2}-[0-9]{4})',
];

export const pointTypes = {
  page: 'page',
  component: 'component',
  module: 'module'
}
