import { Script } from 'gatsby';
import React from 'react';

export const getBVLocale = (locale: string) => {
  const locals: Record<string, string> = {
    en: 'en_US',
    es: 'es_US',
  };

  return locals[locale] || 'en_US';
};

const WrapPageElement = ({ element, props }, pluginOptions) => {
  const lang = getBVLocale(props.pageContext.language);

  return (
    <>
      <Script
        src={`//apps.bazaarvoice.com/deployments/${pluginOptions.clientName}/${pluginOptions.siteID}/${pluginOptions.mode}/${lang}/bv.js`}
        // onLoad={() => {
        //   // Unknown amount of additional pre-requisite network calls need to be made prior to a trackEvent is able to succeed.
        //   setTimeout(() => {
        //     // @ts-ignore - Explicitly calling bvCallback upon pixel loaded confirmation.
        //     window.bvCallback(window.BV);
        //   }, 1000);
        // }}
      />
      {element}
    </>
  );
};

export default WrapPageElement;
