import Icon from '@mdi/react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@purinanbm/pds-ui';
import { useLocation } from '@reach/router';
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { camelCase } from 'lodash';
import React, { useEffect } from 'react';
import launchDarklyLogo from 'src/assets/icons/launchdarkly.svg';
import { mdiMenu } from 'src/assets/icons/mdiIcons';
import { LD_KEYS } from 'src/common/enums';
import { useAuth } from 'src/hooks/useAuth';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import PurinaToggletip from '../toggletip/Toggletip';

type LDFlagStore = {
  ldUsers: Record<string, any>;
  setUserContext: (userKey: string, context: any) => void;
  setLdUserId: (newUserId: string) => void;
  ldUserId: string;
};

const useLDFlagStore = create<LDFlagStore>()(
  persist(
    (set, get) => ({
      ldUserId: '',
      ldUsers: {},
      setUserContext: (userKey, context) =>
        set({ ldUsers: { ...get().ldUsers, [userKey]: context } }),
      setLdUserId: (newUserId: string) => set({ ldUserId: newUserId }),
    }),
    {
      name: 'ld-flags',
    },
  ),
);

const LaunchDarklyFlags = () => {
  const ldClient = useLDClient();
  const { user } = useAuth();
  const setUserContext = useLDFlagStore(state => state.setUserContext);
  const setLdUserId = useLDFlagStore(state => state.setLdUserId);
  const ldUsers = useLDFlagStore(state => state.ldUsers);
  const ldUserId = useLDFlagStore(state => state.ldUserId);
  const allFlags = ldClient?.allFlags() || {};
  const activeContext = ldUsers[ldClient?.getContext().key as string] ?? ldClient?.getContext();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const userIdFromQueryParams = urlParams.get(LD_KEYS.LD_USER_ID);

  /**
   * This useEffect should only run for anonymous users.
   * Logic intended to work only when the ld-user-id query param is available
   */
  React.useEffect(() => {
    if (user) return;
    // Check localstore first for user id
    const userIdFromStore = ldUserId;
    // Overrides user id if it is present in URL and updates it in store
    const userId = userIdFromQueryParams || userIdFromStore;
    if (userIdFromQueryParams) setLdUserId(userId);

    if (userId && ldClient) {
      ldClient?.identify({ kind: 'user', key: userId, anonymous: false });
    }
  }, [ldClient, user, userIdFromQueryParams, setLdUserId, ldUserId]);

  useEffect(() => {
    if (!ldClient || userIdFromQueryParams) return;
    const activeFlags = ldClient.allFlags();

    Object.keys(activeFlags).forEach(flag => {
      // #88: LaunchDarkly Tag
      sendIt({
        event: 'launch_darkly',
        eventCategory: 'launch_darkly',
        eventAction: 'launch_darkly',
        eventLabel: flag,
        eventParams: {
          feature_name: flag,
          index: activeFlags[flag] ? 'flag 1' : 'flag 0',
        },
      });
    });
  }, [ldClient, userIdFromQueryParams]);

  useEffect(() => {
    if (!user?.email || !ldClient) return;
    const newContext = {
      ...activeContext,
      kind: 'user',
      anonymous: false,
      email: user.email,
    };
    setUserContext(activeContext.key as string, newContext);
    ldClient.identify(newContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email, ldClient, setUserContext]);

  const onChange = (flag: string) => {
    if (!ldClient) return;
    let newContext = {};

    // Handle first item checked after reset.
    if (activeContext?.availableFeatures === undefined) {
      newContext = {
        ...activeContext,
        availableFeatures: Object.keys(allFlags).filter((featureFlag: string) => {
          if (featureFlag === flag && ldClient.variation(flag)) return false;
          if (featureFlag === flag && !ldClient.variation(flag)) return true;
          return ldClient.variation(featureFlag);
        }),
      };
    }

    if (activeContext?.availableFeatures !== undefined && ldClient.variation(flag)) {
      newContext = {
        ...activeContext,
        availableFeatures: activeContext.availableFeatures?.length
          ? activeContext.availableFeatures.filter((feature: string) => feature !== flag)
          : [flag],
      };
    }

    if (activeContext?.availableFeatures !== undefined && !ldClient.variation(flag)) {
      newContext = {
        ...activeContext,
        availableFeatures: [...activeContext.availableFeatures, flag],
      };
    }

    ldClient.identify(newContext);
    setUserContext(activeContext.key as string, newContext);
  };

  const onResetClick = () => {
    if (!ldClient) return;

    // Reset to undefined in order to not evaluate against QA rules in LaunchDarkly.
    const newContext = {
      ...activeContext,
      availableFeatures: undefined,
    };
    setUserContext(activeContext.key as string, newContext);
    ldClient.identify(newContext);
  };

  if (!allFlags['launch-darkly-beta-banner']) return null;

  return (
    <div className="pds-fixed pds-bottom-0 pds-right-0 pds-z-[10000] pds-rounded-t pds-border-l-medium pds-border-t-medium pds-border-solid pds-border-white pds-bg-neutral pds-px-4 pds-py-2">
      <Dialog>
        <DialogTrigger className="pds-flex pds-gap-2">
          <Icon path={mdiMenu} size={1} color="#fff" />
          <img src={launchDarklyLogo} alt="LaunchDarkly" width={150} />
        </DialogTrigger>
        <DialogContent className="pds-py-4" asChild>
          <div>
            <DialogClose />
            <DialogTitle className="pds-text-title-md">LaunchDarkly Flags</DialogTitle>
            <p>Welcome {user?.name}, active flags for testing are listed below.</p>
            <p className="pds-text-body-sm">
              Note: Some flags listed might be turned off in the environment, and therefor you will
              be unable to toggle. Additionally, some flags have a pre-requisite flag. Toggling a
              pre-requisite flag might toggle another flag.
            </p>
            <ul className="pds-bg-neutral/.2 pds-max-h-[300px] pds-overflow-scroll">
              {Object.keys(allFlags)
                .sort()
                .map(flag => (
                  <li className="pds-mb-3" key={flag}>
                    <Checkbox
                      id={flag}
                      // Next line disabled due to this being example pattern in pds-ui.
                      // eslint-disable-next-line react/no-unstable-nested-components
                      label={() => <span className="pds-text-neutral">{camelCase(flag)}</span>}
                      onCheckedChange={() => onChange(flag)}
                      checked={allFlags[flag]}
                    />
                  </li>
                ))}
            </ul>
            <Button
              buttonColor="neutral"
              buttonStyle="outlined"
              className="pds-mx-auto"
              onClick={onResetClick}
            >
              Reset Flags&nbsp;
              <PurinaToggletip>
                <span className="pds-text-neutral">
                  Reset all flags to default values, without QA attribute overrides.
                </span>
              </PurinaToggletip>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LaunchDarklyFlags;
